<template>
  <div id="selectRegisterIndividual">
    <div class="container">
      <div class="row justify-content-center vh-100">
        <div class="col-lg-10 col-md-10 col-12 align-self-center">
          <div class="card">
            <div class="card-body login-card-body p-0 m-0">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12 py-5 px-5">
                  <div class="logoBox text-center">
                    <img
                      class="h35"
                      src="../../assets/img/logo.png"
                      style="height: 100px"
                    />
                  </div>

                  <div class="mt-5">
                    <form v-on:submit.prevent="fnRegister">
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          v-model="user.first_name"
                        />
                        <label class="floatingLabel">Given name</label>
                        <div class="invalid-feedback" v-if="errors.first_name">
                          {{ errors.first_name[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          v-model="user.last_name"
                        />
                        <label class="floatingLabel">Family name</label>
                        <div class="invalid-feedback" v-if="errors.last_name">
                          {{ errors.last_name[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-3">
                        <input
                          type="email"
                          class="form-control"
                          placeholder=" "
                          v-model="user.email"
                        />
                        <label class="floatingLabel">Email</label>
                        <div class="invalid-feedback" v-if="errors.email">
                          {{ errors.email[0] }}
                        </div>
                      </div>

                      <!-- Int Tell Country -->
                      <div class="form-group multiselect">
                        <b-form-select
                          value-field="dial_code"
                          text-field="name"
                          placeholder=" "
                          :options="optionsIntTel"
                          v-model="user.int_tel_code"
                        >
                        </b-form-select>
                        <label class="floatingLabel vueBootSelect"
                          >Country</label
                        >
                        <div
                          class="invalid-feedback col-12"
                          v-if="errors.country_code"
                        >
                          {{ errors.country_code[0] }}
                        </div>
                      </div>

                      <!-- Phone number -->
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          v-model="user.mobile_no"
                        />
                        <label class="floatingLabel"
                          >Mobile no
                          <span class="fs-700 text-danger"
                            >(Without country code)</span
                          ></label
                        >
                        <div class="invalid-feedback" v-if="errors.mobileNo">
                          {{ errors.mobileNo[0] }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="icheck-primary">
                            <b-form-checkbox
                              id="checkbox-1"
                              v-model="user.terms_status"
                              name="checkbox-1"
                              value="1"
                              unchecked-value=""
                            >
                              <p class="fs-700">
                                I acknowledge and agree to the CV Screen
                                <a
                                  href="https://cvscreen.com.au/terms-conditions/"
                                  target="_blank"
                                  >terms and conditions</a
                                >
                              </p>
                            </b-form-checkbox>
                            <div
                              class="invalid-feedback"
                              v-if="errors.terms_status"
                            >
                              {{ errors.terms_status[0] }}
                            </div>
                          </div>
                          <div class="icheck-primary">
                            <b-form-checkbox
                              id="checkbox-2"
                              v-model="user.collect_personal_information"
                              name="checkbox-2"
                              value="1"
                              unchecked-value=""
                            >
                              <p class="fs-700">
                                I agree for CV Screen to collect my personal
                                information in accordance with their
                                <a
                                  href="//cvscreen.com.au/privacy-policy"
                                  target="_blank"
                                  >privacy policy</a
                                >
                              </p>
                            </b-form-checkbox>
                            <div
                              class="invalid-feedback"
                              v-if="errors.collect_personal_information"
                            >
                              {{ errors.collect_personal_information[0] }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <p class="mb-1 col-8">
                          <router-link to="/" class="text-center"
                            >I already have an account</router-link
                          >
                        </p>
                        <div class="col-4 text-right">
                          <button type="submit" class="btn btn-info btn-sm">
                            <b-spinner
                              small
                              v-if="loading"
                              class="mr-2"
                            ></b-spinner>
                            <span v-if="loading">Loading...</span>
                            <span v-else>Continue</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-6 col-12 bg-success"
                  id="registerIndividualBoxImg"
                >
                  <div class="p-4">
                    <h3 class="mt2000 fw-500">Sign Up for Your Account</h3>
                    <div class="row">
                      <div class="col-12">
                        <p class="fw-100">
                          Ordering CV Screen offers a seamless and secure online
                          platform for ordering background checks, eliminating
                          the need for printing, scanning, or postage. With just
                          a few clicks from your computer or phone, you can
                          initiate a check anytime, anywhere. Here's how it
                          works:
                        </p>
                        <ol>
                          <li>
                            Choose and pay for the type of check you require.
                          </li>
                          <li>
                            Fill out the online application form and upload
                            necessary identity documents to ensure compliance
                            with legal requirements.
                          </li>
                          <li>
                            Leave the rest to CV Screen – it's that simple!
                          </li>
                        </ol>
                        <p class="fw-100">
                          Please provide a valid phone number for account
                          verification.
                        </p>

                        <p class="mb-0 fw-100">
                          Stay updated on the progress of your check with
                          real-time status updates delivered via email. With a
                          processing time of just 30 minutes, CV Screen
                          collaborates closely with police agencies and the ACIC
                          to deliver most police checks within 24 hours.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";
import { intTel } from "../../services/data.service";

export default {
  data() {
    return {
      errors: {},
      loading: false,
      optionsIntTel: intTel,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        int_tel_code: "+61",
        mobile_no: "",
        terms_status: "",
        collect_personal_information: "",
        role_id: 3,
        remember_me: false,
      },
    };
  },
  methods: {
    fnRegister: async function () {
      try {
        this.loading = true;
        // Progress bar
        this.$Progress.start();
        const res = await authService.register(this.user);

        if (res.data.status_code === 200) {
          this.$router.push({ name: "welcomeMessage" });
        }

        // Progress bar
        this.$Progress.finish();

        this.errors = {};
      } catch (error) {
        console.log(error);
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Registration",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Registration",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Registration",
              "Something wrong, please try again!"
            );
            break;
        }

        // Progress fail (error)
        this.$Progress.fail();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
